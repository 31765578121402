import {map} from "./utils";

const _window = $(window);
const body = $('body');
import {goToByScroll} from './go-to-by-scroll';


$("[data-paroller-factor]").paroller();


let $grid = $('.grid').masonry({
	itemSelector:    '.grid-item',
	columnWidth:     '.grid-sizer',
	percentPosition: true
});

// layout Masonry after each image loads
$grid.imagesLoaded().progress(function () {
	$grid.masonry('layout');
});


// Scroll to next section
$('a[href="#next"]').on('click', function () {
	let next = $(this).closest('[class*="module"]').next();

	goToByScroll(next);
});


$('.sidebar-contact-form__toggle').on('click', function () {
	$('.sidebar-contact-form').toggleClass('sidebar-contact-form--open');
});


// Create and Initialise the Map (required) our google map below

let mapElement = document.getElementById('map');

if (mapElement !== null)
{
	google.maps.event.addDomListener(window, 'load', init);
}

function init() {

	if (mapElement)
	{
		let isDraggable = !('ontouchstart' in document.documentElement);

		let mapOptions = {

			// How zoomed in you want the map to start at (always required)
			draggable: isDraggable,

			zoom: 5,

			scrollwheel: false,
			// The latitude and longitude to center the map (always required)

			center: new google.maps.LatLng(45.583321, 6.800000), // Your Address Here

			// How you would like to style the map.
			// This is where you would paste any style found on [Snazzy Maps][1].
			// copy the Styles from Snazzy maps,  and paste that style info after the word "styles:"

			styles: [{stylers: [{hue: '#de6b1e'}, {saturation: 0}]}, {
				featureType: 'water',
				elementType: 'geometry',
				stylers:     [{lightness: 50}, {visibility: 'simplified'}]
			}, {featureType: 'road', elementType: 'labels', stylers: [{visibility: 'off'}]}]

		};


		// Create the Google Map using out element and options defined above
		let map = new google.maps.Map(mapElement, mapOptions);
		// Define the image to use for the map marker (58 x 44 px)

		let image = {
			url:  '/wp-content/themes/clc/images/map.png',
			size: new google.maps.Size(94, 45)
		};

		// Define the Latitude and Longitude for the map location

		let myLatLng = new google.maps.LatLng(45.583321, 12.351421);
		let myLatLngMilano = new google.maps.LatLng(45.490597, 9.204954);
		let myLatLngParis = new google.maps.LatLng(48.90594068237543, 2.087363198233109);

		// Define the map marker characteristics

		let mapMarker1 = new google.maps.Marker({
			position: myLatLng,
			map:      map,
			icon:     image,
			title:    'Calicantus'

		});

		let mapMarker2 = new google.maps.Marker({
			position: myLatLngMilano,
			map:      map,
			icon:     image,
			title:    'Calicantus'

		});

		let mapMarker3 = new google.maps.Marker({
			position: myLatLngParis,
			map:      map,
			icon:     image,
			title:    'Calicantus'

		});
	}
}


// Hubspot forms load event
window.addEventListener('message', event => {
	if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormReady') {

		$('.hbspt-form .hs-form-field:not(.hs-fieldtype-booleancheckbox)').each(function () {
			let labPh = $('label span', this).text();
			$('.input input, .input textarea', this).attr('placeholder', labPh);
			$('label', this).hide();
		});

		$('.cali-newsletter .legal-consent-container').insertAfter($('.cali-newsletter .hs_submit'));
	}
});

/**
 * ON LOAD
 */

_window.on('load', function (event) {


});



/**
 * ON RESIZE
 */

_window.on('resize', function (event) {

	if (event.target === window)
	{
		// trigger resizeEnd event only when the window resizing ends
		if (this.resizeTO)
		{
			clearTimeout(this.resizeTO);
		}
		this.resizeTO = setTimeout(function () {
			$(this).trigger('resizeEnd');
		}, 200);
	}

});



/**
 * ON RESIZE END
 */

// do something when the window resizing ends
_window.on('resizeEnd', function () {

});



/**
 * ON SCROLL
 */
/*window.addEventListener('scroll', function() {
});*/

