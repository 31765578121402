//================================= SHARE TO SOCIAL NETWORKS

const body = $('body');

function social_click(sn, title, url, image) {
	let t = '';
	let u = '';
	let i = '';

	t = (typeof title !== 'undefined')? title: document.title;
	u = (typeof url !== 'undefined')? url: document.URL;
	i = (typeof image !== 'undefined')? image: '';

	switch (sn) {
		case 'facebook':
			url = 'https://www.facebook.com/sharer.php?u=' + encodeURIComponent(u) + '&t=' + encodeURIComponent(t);
			break;

		case 'twitter':
			url = 'https://twitter.com/intent/tweet?url=' + encodeURIComponent(u) + '&text=' + encodeURIComponent(t);
			break;

		case 'pinterest':
			url = 'https://pinterest.com/pin/create/button/?url='+encodeURIComponent(u)+'&media='+encodeURIComponent(i)+'&description=' + encodeURIComponent(t);
			break;

		case 'linkedin':
			url = 'https://www.linkedin.com/shareArticle?mini=true&url='+encodeURIComponent(u)+'&title='+encodeURIComponent(t)+'&summary=&source='+encodeURIComponent(t);
			break;
	}

	window.open(url, 'socials', 'height=450,width=880,toolbar=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=no,modal=no');
}

// share on facebook
body.on('click', '.share_facebook', function(e){
	e.preventDefault();

	social_click('facebook', $(this).data('title'), $(this).data('url'));

	return false;
});

// share on twitter
body.on('click', '.share_twitter', function(e){
	e.preventDefault();

	social_click('twitter', $(this).data('title'), $(this).data('url'));

	return false;
});

// share on pinterest
body.on('click', '.share_pinterest', function(e){
	e.preventDefault();

	social_click('pinterest', $(this).data('title'), $(this).data('url'), $(this).data('image'));

	return false;
});

// share on linkedin
body.on('click', '.share_linkedin', function(e){
	e.preventDefault();

	social_click('linkedin', $(this).data('title'), $(this).data('url'));

	return false;
});
