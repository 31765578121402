//================================= SMOOTH SCROLL

import { goToByScroll } from './go-to-by-scroll';

const _window = $(window);
const body = $('body');

body.on('click.smoothscroll', 'a[href*="#"]', function (e) {
	let target = this.hash,
		$target = $(target);

	if ($target.length > 0)
	{
		e.preventDefault();

		let offset = $(this).data('offset');
		if (typeof offset === 'undefined')
		{
			//offset = -$('#header').outerHeight();
		}

		goToByScroll($target, offset);

		return false;
	}
});


_window.on('load', function (event) {

	// smooth scroll to anchor on load
	if (location.hash)
	{
		let offset = -$('#header').outerHeight();
		setTimeout(function () {
			goToByScroll($(location.hash), offset);
		}, 200);
	}

});