const _window = $(window);

$('.slideshow-module-testimonials .swiper').each(function () {

	if ($(this).find('.swiper-slide').length > 1)
	{
		let slideshowSwiper = new Swiper('.slideshow-module-testimonials .swiper', {
			
			slidesPerView: 1,
            spaceBetween:  20,
            speed:         900,
            loop:          false,
            autoplay:      {
                delay: 8000,
            },
			autoHeight:    false,
           
			
			navigation:    {
				nextEl: '.slideshow-module-testimonials .swiper-button-next',
				prevEl: '.slideshow-module-testimonials .swiper-button-prev',
			},
			
			pagination: {
                enabled: false
            },
			// Responsive breakpoints
			breakpoints: {
				// when window width is >= 800px
				800: {
					spaceBetween: 20,
					slidesPerView: 1.20,
					loop:          false,
					autoplay:		false,
					autoHeight:    false,
					pagination: {
						enabled: false
					},
				},
				// when window width is >= 1200px
				1400: {
				spaceBetween: 20,
				slidesPerView: 1.40,
				loop:          false,
				autoplay:		false,
				autoHeight:    false,
					pagination: {
						enabled: false
					},
				}
			}
		});
	}
});


let swiper = document.querySelector('.swiper');

if (swiper !== null)
{
	swiper.addEventListener('load', (e) => {
		e.currentTarget.swiper.updateAutoHeight(200);
	}, {capture: true});
}


let heroHomeSwiper = new Swiper('.hero-home .swiper', {
	slidesPerView: 1,
	speed:         600,
	loop:          true,
	autoHeight:    true,
	autoplay:      {
		delay: 6000,
	},
	effect:        'fade',
	fadeEffect:    {
		crossFade: true
	},
	pagination:    {
		el:        '.swiper-pagination',
		clickable: true,
	},
	on:            {
		slideChange:              function (swiper) {

			setTimeout(function () {
				let currentSlide = $('.swiper-slide-active', '.hero-home');

				$('.title--s', currentSlide).addClass('fadeIn animated').next().addClass('fadeIn animated');

				$('.title--xl', currentSlide).addClass('fadeIn animated').next().addClass('fadeIn animated');
			}, 500);

		},
		slideChangeTransitionEnd: function (swiper) {

			setTimeout(function () {
				let inactiveSlides = $('.swiper-slide:not(.swiper-slide-active)', '.hero-home');
				$('.title--s', inactiveSlides).removeClass('fadeIn animated').next().removeClass('fadeIn animated');

				$('.title--xl', inactiveSlides).removeClass('fadeIn animated').next().removeClass('fadeIn animated');
			}, 500)

		}
	}
});


  new Swiper('.blog-carosell', {
	slidesPerView: 1.20,
	spaceBetween: 20,
	navigation:    {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	pagination: {
		el: ".swiper-pagination",
		type: 'bullets',
		clickable: true
	},
	// Responsive breakpoints
	breakpoints: {
		// when window width is >= 800px
		800: {
			spaceBetween: 0,
			slidesPerView: 2.20,
			pagination: {
                enabled: false
            },
		},
		// when window width is >= 1200px
		1400: {
		spaceBetween: 0,
		slidesPerView: 3.20,
			pagination: {
				enabled: false
			},
		}
	}
  });



  new Swiper('.carosello-clienti .swiper', {
	slidesPerView:2, 
	spaceBetween: 15,
  	loop: true,
	freeMode: true,        // Abilita il movimento libero senza bloccarsi alle slide
	freeModeMomentum: true,// Abilita l'inerzia del movimento
	autoplay: {
		delay: 2000,
	  },
	// Responsive breakpoints
	breakpoints: {
		// when window width is >= 992px

		720: {
			slidesPerView: 2,
			spaceBetween: 15,
			loop: true,
			freeMode: true,        // Abilita il movimento libero senza bloccarsi alle slide
			freeModeMomentum: true,// Abilita l'inerzia del movimento
		},
		992: {
			slidesPerView: 4,
			spaceBetween: 15,
			loop: true,
			freeMode: true,        // Abilita il movimento libero senza bloccarsi alle slide
			freeModeMomentum: true,// Abilita l'inerzia del movimento
		},
		// when window width is >= 1200px
		1200: {
			slidesPerView: 6,
			spaceBetween: 20,
			loop: true,
			freeMode: true,        // Abilita il movimento libero senza bloccarsi alle slide
			freeModeMomentum: true,// Abilita l'inerzia del movimento
			autoplay: {
				delay: 2000,
				
			  },
		}
	}
  });


  new Swiper('.testimonial__settori .swiper', {
	slidesPerView: 2, 
	spaceBetween: 15,
	autoplay: {
		delay: 5000,
	  },
	// Responsive breakpoints
	breakpoints: {
		// when window width is >= 576px
		576: {
			slidesPerView: 2,
			spaceBetween: 20,
		},
		// when window width is >= 1200px
		1200: {
			slidesPerView: 3,
			spaceBetween:  40,
		}
	}
  });

new Swiper('.carosello-clienti3', {
	slidesPerView:2,
	grid: {
	  rows: 2,
	},
	spaceBetween: 15,
	pagination: {
	  el: ".swiper-pagination",
	  clickable: true,
	},
	breakpoints: {
		// when window width is >= 576px
		576: {
			slidesPerView: 3,
			grid: {
			rows: 2,
			},
			spaceBetween: 20,
		},
		// when window width is >= 1200px
		1200: {
			slidesPerView: 4,
			grid: {
			rows: 3,
			},
			spaceBetween: 30,
		}
	}
  });


/*
new Swiper('.intro-home-carosello-mobile', {
	slidesPerView: 1.20,
	spaceBetween: 20,
	navigation:    {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	pagination: {
		el: ".swiper-pagination",
		type: 'bullets',
		clickable: true
	},
	breakpoints: {
		// when window width is >= 576px
		800: {
			slidesPerView: 2.20,
			spaceBetween: 0,
			pagination: {
				enabled: false
			},
			autoplay: {
				delay: 5000,
			  },
		},
		// when window width is >= 1200px
		1400: {
			slidesPerView: 3,
			spaceBetween: 0,
			navigation: {
                enabled: false
            }
		}
	}
});*/


new Swiper('.intro-home-carosello-mobile', {
	slidesPerView: 1.20,
	spaceBetween: 20,
	navigation:    {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	pagination: {
		el: ".swiper-pagination",
		type: 'bullets',
		clickable: true
	},
	// Responsive breakpoints
	breakpoints: {
		// when window width is >= 800px
		800: {
			spaceBetween: 0,
			slidesPerView: 2.20,
			pagination: {
                enabled: false
            },
		},
		// when window width is >= 1200px
		1400: {
		spaceBetween: 0,
		slidesPerView: 3.20,
			pagination: {
				enabled: false
			},
		}
	}
  });


new Swiper('.steps-module-page', {
	slidesPerView: 1.20,
	spaceBetween: 20,
	navigation:    {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	pagination: {
		el: ".swiper-pagination",
		type: 'bullets',
		clickable: true
	},
	// Responsive breakpoints
	breakpoints: {
		// when window width is >= 576px
		576: {
			spaceBetween: 20,
			slidesPerView: 2.20,
			pagination: {
                enabled: false
            },
		},
		// when window width is >= 1200px
		1400: {
		spaceBetween: 20,
		slidesPerView: 3.20,
			pagination: {
				enabled: false
			},
		}
	}
});


new Swiper('.steps-case-studies', {
    slidesPerView: 1.20,
    spaceBetween: 20,
    navigation:    {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    pagination: {
        el: ".swiper-pagination",
        type: 'bullets',
        clickable: true
    },
    // Responsive breakpoints
    breakpoints: {
        // when window width is >= 576px
        576: {
            spaceBetween: 20,
            slidesPerView: 1.40,
            pagination: {
                enabled: false
            },
        },
		1020: {
            spaceBetween: 20,
            slidesPerView: 2.40,
            pagination: {
                enabled: false
            },
        },
        // when window width is >= 1200px
        1400: {
        spaceBetween: 20,
        slidesPerView: 2.20,
            pagination: {
                enabled: false
            },
        },
		1700: {
			slidesPerView: 2.50,
			pagination: {
                enabled: false
            },
		},
		1900: {
			slidesPerView: 2.20,
			pagination: {
                enabled: false
            },
		},
		2300: {
			slidesPerView: 2.40,
			pagination: {
                enabled: false
            },
		}
    }
});

new Swiper('.gallery_calicantus .swiper', {
	slidesPerView:1.25, 
	spaceBetween: 20,
	navigation:    {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	autoplay: {
		delay: 2000,
	  },
	pagination: {
		el: ".swiper-pagination",
		type: 'bullets',
		clickable: true
	},
	// Responsive breakpoints
	breakpoints: {
		// when window width is >= 992px

		720: {
			slidesPerView: 1.25,
			spaceBetween: 20,
			pagination: {
                enabled: false
            },
		},
		992: {
			slidesPerView: 1.5,
			spaceBetween: 20,
			pagination: {
                enabled: false
            },
		},
		// when window width is >= 1200px
		1200: {
			slidesPerView: 1.5,
			spaceBetween: 20,
			pagination: {
                enabled: false
            },
		}
	}
  });



  new Swiper('.blog-carosell-sticky', {
	slidesPerView: 1.20,
	spaceBetween: 0,
	navigation:    {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	pagination: {
		el: ".swiper-pagination",
		type: 'bullets',
		clickable: true
	},
	// Responsive breakpoints
	breakpoints: {
		// when window width is >= 800px
		800: {
			spaceBetween: 5,
			slidesPerView: 2.20,
			pagination: {
                enabled: false
            },
		},
		// when window width is >= 1200px
		1400: {
		spaceBetween: 5,
		slidesPerView: 1.40,
			pagination: {
				enabled: false
			},
		}
	}
  });